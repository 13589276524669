<template>
  <a-modal
    v-model="isModalActive"
    class="app-params"
    :title="$t('appSettings.title')"
    width="800px"
    maskClosable
    transitionName="none"
    maskTransitionName="none"
    :okText="$t('appSettings.save')"
    :cancelText="$t('appSettings.cancel')"
    @cancel="resetData"
    @ok="saveParams"
  >
    <a-spin
      class="edit-form-spinner"
      :spinning="loading"
    >
      <div class="spin-wrap">
        <p v-if="appSettingsLoadFailed && authorized">
          <a @click="logout">{{ $t('appSettings.resetAuthToken') }}</a>
        </p>
        <a-alert
          v-if="appSettingsLoadFailed"
          class="app-params__alert"
          type="error"
          :message="$t('appSettings.loadFailed')"
        />
        <section
          v-else-if="hasGlobalParams"
          class="app-params__section"
        >
          <h4>{{ $t('appSettings.globalParams') }}</h4>
          <a-row
            v-for="(param, key) of globalParams"
            :key="key"
            :class="['app-params__field', { 'has-error': !isFieldValid(key) }]"
            type="flex"
            :gutter="16"
          >
            <a-col :sm="6">
              <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
              <label :for="'glb-app-prm' + key">
                {{ $t(`appSettings.param.${key}`) }}
                <span v-if="globalParams[key].required">*</span>
              </label>
            </a-col>
            <a-col :sm="18">
              <a-checkbox
                v-if="param.type === 'boolean'"
                :id="'glb-app-prm' + key"
                v-model="globalParams[key].value"
              />
              <a-select
                v-else-if="param.type === 'timezone'"
                :id="'glb-app-prm' + key"
                v-model="globalParams[key].value"
                showSearch
              >
                <a-select-option
                  v-for="zone of timezones"
                  :key="zone"
                  :value="zone"
                >
                  {{ zone }}
                </a-select-option>
              </a-select>
              <a-input
                v-else
                :id="'glb-app-prm' + key"
                v-model="globalParams[key].value"
                size="small"
              />
            </a-col>
          </a-row>
        </section>

        <section class="app-params__section">
          <h4>{{ $t('appSettings.localParams') }}</h4>
          <a-row
            v-for="(param, key) of localParams"
            :key="key"
            class="app-params__field"
            type="flex"
            :gutter="16"
          >
            <a-col :sm="6">
              <!-- eslint-disable-next-line vuejs-accessibility/label-has-for -->
              <label :for="'loc-app-prm' + key">{{ $t(`appSettings.param.${key}`) }}</label>
            </a-col>
            <a-col :sm="18">
              <a-input
                :id="'loc-app-prm' + key"
                v-model="localParams[key].value"
                size="small"
                allowClear
              />
            </a-col>
          </a-row>
        </section>
      </div>
    </a-spin>
  </a-modal>
</template>

<script>
import moment from 'moment';
import store from '@/store';
import { storage, deepClone } from '@/helpers';
import { appSettings } from '@/AppSettings';

export default {
  name: 'AppSettingsModal',
  props: {
    value: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      globalParams: {},
      localParams: {
        apiUrl: { envKey: 'API_URL', value: '' },
        authUrl: { envKey: 'AUTH_URL', value: '' },
      },
    };
  },
  computed: {
    isModalActive: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    appSettingsLoadFailed() {
      return !appSettings.loading && !appSettings.params;
    },
    appSettingsLoaded() {
      return !appSettings.loading;
    },
    hasGlobalParams() {
      return Object.keys(this.globalParams).length > 0;
    },
    authorized() {
      return store.state.user.authorized;
    },
    timezones() {
      return moment.tz.names();
    },
  },
  watch: {
    appSettingsLoaded: {
      immediate: true,
      handler(loaded) {
        if (loaded) {
          this.loading = false;
          this.resetData();
        }
      },
    },
  },
  methods: {
    async saveParams() {
      this.loading = true;
      if (appSettings.params) await appSettings.save(this.globalParams, this);

      Object.values(this.localParams).forEach(({ envKey, value }) => {
        storage.set(`VUE_APP_${envKey}`, value);
      });

      window.location.reload(true);
    },
    resetData() {
      this.resetGlobalParams();
      Object.keys(this.localParams).forEach((key) => {
        this.localParams[key].value = storage.get(`VUE_APP_${key}`) || '';
      });
    },
    resetGlobalParams() {
      this.globalParams = appSettings.params ? deepClone(appSettings.params) : {};
    },
    logout() {
      this.$emit('logout');
    },
    isFieldValid(key) {
      const isRequired = this.globalParams[key].required;
      return !isRequired || this.globalParams[key].value;
    },
  },
};
</script>

<style lang="scss">
.app-params {
  &__field + &__field {
    margin-top: 16px;
  }

  &__section,
  &__alert {
    & + & {
      margin-top: 20px;
    }
  }

  .ant-modal-body {
    padding: 20px 24px 5px;
  }

  .ant-select {
    width: 100%;
  }
}
</style>
