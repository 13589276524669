export * from './bus';
export * from './display';
export * from './format';
export * from './dataProcessing';
export * from './formFields';
export * from './formLayoutParameters';
export * from './filesAndMedia';
export * from './colors';
export * from './storage';
export * from './metaQueryParser';

let uid = 0;
export function uniqueId(prefix = 'uid') {
  return `${prefix}-${+new Date()}${uid++}`;
}

export function getTempId() {
  return `_temp_${+new Date()}${uid++}`;
}
export class Cookies {
  static get(name) {
    const matches = document.cookie.match(
      new RegExp(`(?:^|; )${name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1')}=([^;]*)`),
    );

    return matches ? decodeURIComponent(matches[1]) : undefined;
  }

  static set(name, days, value = null) {
    if (name && value !== null) {
      let expires = '';
      if (days) {
        expires = new Date();
        expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
        expires = `; expires=${expires.toGMTString()}`;
      }

      document.cookie = `${name}=${value}${expires}; path=/`;
    }
  }

  static delete(name) {
    Cookies.set(name, '', -1);
  }
}

export function isWideField({ renderer }) {
  return (
    ['gallery', 'image', 'text', 'wysiwyg', 'json', 'component', 'embed'].indexOf(renderer) > -1
  );
}
