<template>
  <div :class="['entity-form', size, { 'one-column': oneColumn }]">
    <div class="entity-form__content">
      <slot name="content">
        <a-tabs
          v-if="data"
          v-model="activeTabKey"
          :animated="false"
          :class="[{ 'no-tabs': !hasTabs }]"
        >
          <a-tab-pane
            v-for="(pane, index) of tabs"
            :key="index"
            :tab="pane.title"
          >
            <div
              v-if="pane.preview"
              class="entity-form__tabpane preview"
            >
              <div
                v-for="(column, columnIndex) of pane.columns"
                :key="columnIndex"
                class="entity-form__column"
              >
                <kanban-card
                  :fieldsToDisplay="column"
                  :card="{ data }"
                  :showTitles="columnIndex > 0"
                  :firstFieldBold="!columnIndex"
                  readOnly
                  fullView
                  :parentType="type"
                />
              </div>
            </div>
            <validation-observer
              v-else
              ref="validationObserver"
              class="entity-form__tabpane"
            >
              <div
                v-for="(column, columnIndex) of pane.columns"
                :key="columnIndex"
                class="entity-form__column"
              >
                <a-row
                  :gutter="16"
                  type="flex"
                >
                  <a-col
                    v-for="fieldDescriptor of column"
                    :key="fieldDescriptor.name"
                    :sm="wideField(fieldDescriptor)"
                    :xs="24"
                  >
                    <edit-form-field-wrap
                      v-if="!fieldDescriptor.hidden"
                      v-model="data[fieldDescriptor.name]"
                      :fieldDescriptor="fieldDescriptor"
                      :label="fieldDescriptor"
                      :parentType="type"
                      :disabled="disabled"
                      :readOnly="readOnly"
                      :size="size"
                      firstModalDepthLevel
                      @updateEntity="updateEntity"
                    />
                  </a-col>
                </a-row>
              </div>
            </validation-observer>
          </a-tab-pane>
        </a-tabs>
      </slot>

      <slot
        name="bottomControls"
        v-bind="{
          save: updateSelf,
          modelIntact,
          hasVisibleFields,
          duplicate,
          deleteSelf,
          requestError,
        }"
      >
      </slot>
    </div>
  </div>
</template>

<script>
import store from '@/store';
import { bus, isWideField, deepClone } from '@/helpers';
import EntityService from '@/services/EntityService';
import KanbanCard from '@/components/page/kanban/KanbanCard.vue';
import EditFormFieldWrap from './EditFormFieldWrap.vue';

export default {
  name: 'EntityForm',
  components: {
    EditFormFieldWrap,
    KanbanCard,
  },

  props: {
    id: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      required: true,
    },
    draftType: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'default',
    },
    oneColumn: {
      type: Boolean,
      default: false,
    },
    formLayout: {
      type: Object,
      required: true,
    },
    formConfig: {
      type: Object,
      required: true,
    },
    descriptors: {
      type: Array,
      default: null,
    },
    sourceData: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    isModal: {
      type: Boolean,
      default: false,
    },
    canReadModel: {
      type: Boolean,
      default: false,
    },
    canCreateModel: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      modelIntact: true,
      activeTabKey: 0,
      data: null,
      requestError: null,
    };
  },

  computed: {
    tabs() {
      const previewMode = this.isModal && this.formConfig.params.previewModeEnabled;

      let tabs = this.formLayout.tabs.map((tab) => {
        tab.columns = tab.columns.filter((column) => {
          const x = column.filter((field) => !field.hidden).length;
          return x > 0;
        });

        return tab.columns.length ? tab : null;
      });

      const tabNames = [this.$t('entity.tab.primary'), this.$t('entity.tab.secondary')];

      tabs = tabs.filter((tab) => !!tab);
      tabs.forEach((tab, index) => {
        tab.title = tabNames[index];
      });

      // generate preview tab
      if (previewMode) {
        const allFields = this.formLayout.tabs.map((tab) => tab.columns.flat()).flat();
        const mainColumn = allFields.filter(this.isPrevieWideField);
        const sideColumn = allFields.filter((field) => !this.isPrevieWideField(field));

        tabs.unshift({
          title: this.$t('entity.tab.preview'),
          preview: true,
          columns: [mainColumn, sideColumn].filter((col) => col.length),
        });
      }

      return tabs;
    },
    hasTabs() {
      return this.tabs.length > 1;
    },
    isDraft() {
      return this.id.startsWith('_temp');
    },
    hasVisibleFields() {
      return this.tabs.every((tab) =>
        tab.columns.every((column) => column.filter((field) => field.renderer !== 'hidden').length),
      );
    },
  },

  watch: {
    sourceData: {
      immediate: true,
      handler(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.initData();
        }
      },
    },
    data: {
      deep: true,
      handler(newVal, oldVal) {
        this.modelIntact = false;

        if (newVal === oldVal) {
          store.mutate.storeFormDraft(this.draftType, this.id, newVal);
        }
      },
    },
    tabs: {
      immediate: true,
      handler(now, before) {
        const hadPreviewTab = before && before[0]?.preview;
        const hasPreviewTab = now[0]?.preview;
        let activeTabKey = this.activeTabKey;

        if (!before) {
          activeTabKey = this.isDraft && hasPreviewTab ? 1 : 0;
        } else if (hadPreviewTab && !hasPreviewTab) {
          activeTabKey -= 1;
        } else if (!hadPreviewTab && hasPreviewTab) {
          activeTabKey += 1;
        }

        this.activeTabKey = Math.max(0, Math.min(activeTabKey, now.length - 1));
      },
    },
  },

  methods: {
    initData() {
      const data = deepClone(this.sourceData);
      this.resetEntityData(data);
    },

    resetEntityData(data, dirty = false) {
      this.data = data;
      this.$nextTick(() => {
        this.modelIntact =
          !store.mutate.getFormDraft(this.draftType, this.id) && !dirty && this.hasVisibleFields;
      });
    },

    isPrevieWideField(field) {
      return isWideField(field) || field.renderer === 'string';
    },

    wideField(field) {
      return this.size !== 'small' || this.oneColumn || isWideField(field) ? 24 : 12;
    },

    closeForm() {
      this.$emit('close');
    },

    updateEntity(data, type, { id }) {
      this.saveEntity(data, type, id);
    },

    async updateSelf() {
      let observer = this.$refs.validationObserver;
      observer = this.$refs.validationObserver?.length ? this.$refs.validationObserver[0] : null;

      const valid = !observer || (await observer.validate());

      if (!valid) return;
      this.requestError = null;
      const id = this.id;
      const data = { type: this.type, data: this.data };

      try {
        const { errors } = await this.saveEntity(data, this.type, id);
        this.checkErrors(errors);
      } catch (error) {
        this.requestError = error.message;
        throw error;
      }
    },

    async saveEntity({ data }, type, id, meta = this.descriptors) {
      const { isCreated, response, errors } = await EntityService.createOrUpdate(meta, {
        id,
        type,
        data,
      });

      if (isCreated) {
        if (!this.canReadModel) {
          this.closeForm();
        } else {
          this.$router
            .replace({
              name: this.$route.name,
              params: {
                ...this.$route.params,
                id: response.id,
              },
            })
            .catch(() => {});
        }
      }

      return { response, errors };
    },

    checkErrors(errors) {
      const observer = this.$refs.validationObserver && this.$refs.validationObserver[0];
      if (errors?.length) {
        errors = errors.reduce((prev, error) => {
          prev[error.field.replace(/^\./, '')] = [error.msg];
          return prev;
        }, {});

        observer?.setErrors(errors);
      } else {
        this.modelIntact = true;
        this.checkIfMainConfigUpdated();
        this.showSuccessfulUpdateNotification();
      }
    },

    deleteSelf() {
      if (this.isDraft) {
        this.deleteEntityDraft();
      } else {
        this.deleteEntity();
      }
    },

    deleteEntity() {
      EntityService.delete({ type: this.type, id: this.id })
        .then(() => {
          this.checkIfMainConfigUpdated();
          this.closeForm();
        })
        .catch((error) => {
          this.requestError = error.message;
        });
    },

    deleteEntityDraft() {
      store.mutate.deleteFormDraft(this.type, this.id);
      this.closeForm();
    },

    checkIfMainConfigUpdated() {
      const source = this.sourceData || {};
      if (this.type === 'Config' && (source.title === 'main' || this.data.title === 'main')) {
        bus.$emit('mainConfigUpdated');
      }
    },

    showSuccessfulUpdateNotification() {
      this.$notification.success({
        message: this.$t('entity.savedSuccess'),
      });
    },

    duplicate() {
      const tempId = `_temp_${+new Date()}`;
      const data = { ...this.data };
      delete data.id;

      store.mutate.storeFormDraft(this.type, tempId, data);
      this.$emit('duplicateEntity', this.type, tempId);
    },
  },
};
</script>

<style lang="scss">
.entity-form {
  width: 100%;

  &__content {
    max-width: 100%;
    min-width: 0;
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;

    .ant-tabs.no-tabs > .ant-tabs-bar {
      display: none;
    }

    .ant-tabs {
      width: 100%;
      overflow: visible;
    }

    .ant-spin-nested-loading {
      width: 100%;
    }

    .ant-calendar-picker {
      width: 100%;
    }
  }

  &__tabpane {
    display: flex;
    flex-wrap: wrap;
    min-width: 0;
  }

  &__column:nth-child(1) {
    flex: 1 1 100%;
    min-width: 0;
  }

  &__column:nth-child(2) {
    flex-grow: 1;
    flex-basis: 100%;
    min-width: 0;
    box-sizing: border-box;
  }

  &__footer {
    flex-basis: 100%;
    padding-top: 10px;

    & > button {
      margin-right: 10px;
    }
  }

  &__error.ant-alert {
    margin-top: 20px;
  }

  .ant-breadcrumb-link {
    cursor: pointer;
    &:hover {
      opacity: 0.5;
    }
  }
  .ant-form-item-control {
    line-height: 32px;
  }

  &.small {
    padding-bottom: 20px;

    .entity-field__title,
    .edit-form-checkbox h3 {
      font-size: 100%;
    }

    .entity-field {
      margin-top: 20px;
    }

    .entity-form__footer {
      margin-top: 40px;
    }

    .ant-form-item {
      margin-bottom: 0;
    }

    .ant-form-item-control {
      line-height: 24px;
    }

    .entity-form__column {
      flex: 0 0 100%;
      margin-left: 0;
    }

    .entity-form__tabpane {
      flex-wrap: wrap;
    }
  }

  &.one-column .entity-form {
    &__tabpane {
      flex-wrap: wrap;
    }

    &__column {
      margin-left: 0;
    }

    &__column + .entity-form__column {
      margin-top: 0;
    }

    &.small .entity-form__column:last-child {
      padding-bottom: 30px;
    }
  }
}

@media (min-width: $tabletBreakpoint) {
  .entity-form {
    &__tabpane {
      flex-wrap: nowrap;
    }

    &__column:nth-child(1) {
      flex: 1 1 100%;
    }

    &__column:nth-child(2) {
      margin-left: 40px;
      flex: 0 0 200px;
    }
  }
}

@media (min-width: $desktopBreakpoint) {
  .entity-form {
    &__content {
      max-width: 1100px;
    }

    &__tabpane:not(.preview) {
      padding-top: 10px;
    }

    &__tabpane.preview {
      margin-bottom: -30px;
    }

    &__column:nth-child(2) {
      margin-left: 60px;
    }
  }
}
</style>
