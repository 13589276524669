<template>
  <so-promocode-card
    :orderData="order"
    :b2binfoData="b2binfo"
    :contactData="contact"
    :orderTypeData="orderType"
    :paymentData="payment"
    :promoParamsData="promoParams"
    :courseData="courseQuery || {}"
    :hasPayment="!!paymentQuery"
    :hasPromocodes="hasPromocodes"
    :promocodes="promocodes"
    :isLoading="loading"
    class="so-promocodes__item"
    @setCourse="setCourse"
    @setUserId="setUserId"
    @setValue="setValue"
    @delete="deleteOrder"
    @save="saveData"
    @generate="generateCodes"
  />
</template>

<script>
import store from '@/store';
import { bus, preparePayload } from '@/helpers';
import MetaService from '@/services/MetaService';
import FormConfigService from '@/services/FormConfigService';
import EntityService from '@/services/EntityService';
import GET_QUERY from '@/queries/get';
import TABLE_QUERY from '@/queries/table';
import customQuery from '@/queries/customQuery';
import customMutation from '@/queries/mutations/customMutation';
import SoPromocodeCard from './SoPromocodeCard';

export default {
  name: 'SoPromocode',

  components: {
    SoPromocodeCard,
  },

  props: {
    data: {
      type: Object,
      required: true,
    },
    actionsMeta: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      loadingKeys: 0,
      order: this.data.data,
      b2binfo: {},
      contact: {},
      orderType: {},
      payment: {},
      promoParams: {},
      course: {},

      courseValue: this.data.data.courses?.[0] || null,
      userIdValue: this.data.data.userId || null,
      promocodes: null,
    };
  },

  computed: {
    loading() {
      return this.loadingKeys > 0;
    },
    hasPromocodes() {
      return this.promocodes?.length > 0;
    },
  },

  watch: {
    data() {
      this.order = this.data.data;
    },
    b2binfoQuery(nval) {
      this.b2binfo = nval ? { ...nval.data } : { userId: this.order.userId };
    },
    contactQuery(nval) {
      this.contact = nval ? { ...nval.data } : { userId: this.order.userId };
    },
    orderTypeQuery(nval) {
      this.orderType = nval
        ? { ...nval.data }
        : {
            order: {
              value: `B2bOrders:${this.order.id}`,
              title: this.order.id,
            },
          };
    },
    paymentQuery(nval) {
      this.payment = nval
        ? { ...nval.data }
        : {
            b2bOrderId: {
              value: `B2bOrders:${this.order.id}`,
              title: this.order.id,
            },
          };
    },
    promoParamsQuery(nval) {
      this.promoParams = nval
        ? { ...nval.data }
        : {
            orderRef: {
              value: `B2bOrders:${this.order.id}`,
              title: this.order.id,
            },
          };
    },
    courseQuery(nval) {
      this.course = nval ? { ...nval.data } : {};
    },
  },

  apollo: {
    b2binfoQuery: {
      ...TABLE_QUERY,
      loadingKey: 'loadingKeys',
      variables() {
        return {
          type: 'B2bInfo',
          filters: [
            {
              field: 'userId',
              operator: 'EQUALS',
              value: this.userIdValue.value,
            },
          ],
        };
      },
      update({ table }) {
        return table.documents[0] || null;
      },
      skip() {
        return !this.userIdValue;
      },
    },
    contactQuery: {
      ...TABLE_QUERY,
      loadingKey: 'loadingKeys',
      variables() {
        return {
          type: 'B2BContact',
          filters: [
            {
              field: 'userId',
              operator: 'EQUALS',
              value: this.userIdValue.value,
            },
          ],
        };
      },
      update({ table }) {
        return table.documents[0] || null;
      },
      skip() {
        return !this.userIdValue;
      },
    },
    orderTypeQuery: {
      ...TABLE_QUERY,
      loadingKey: 'loadingKeys',
      variables() {
        return {
          type: 'B2bOrderType',
          filters: [
            {
              field: 'order',
              operator: 'EQUALS',
              value: { value: `B2bOrders:${this.order.id}` },
            },
          ],
        };
      },
      update({ table }) {
        return table.documents[0] || null;
      },
    },
    paymentQuery: {
      ...TABLE_QUERY,
      loadingKey: 'loadingKeys',
      variables() {
        return {
          type: 'B2bOrderPayments',
          filters: [
            {
              field: 'b2bOrderId',
              operator: 'EQUALS',
              value: { value: `B2bOrders:${this.order.id}` },
            },
          ],
        };
      },
      update({ table }) {
        return table.documents[0] || null;
      },
    },
    promoParamsQuery: {
      ...TABLE_QUERY,
      loadingKey: 'loadingKeys',
      variables() {
        return {
          type: 'PromocodeParameters',
          filters: [
            {
              field: 'orderRef',
              operator: 'EQUALS',
              value: { value: `B2bOrders:${this.order.id}` },
            },
          ],
        };
      },
      update({ table }) {
        return table.documents[0] || null;
      },
    },
    courseQuery: {
      ...GET_QUERY,
      loadingKey: 'loadingKeys',
      variables() {
        return {
          id: this.courseValue.value.split(':')[1],
          type: 'SOCourse',
        };
      },
      update({ get }) {
        return get.document.data;
      },
      skip() {
        return !this.courseValue;
      },
    },
  },

  async created() {
    this.promocodes = await this.getPromocodes();
  },

  methods: {
    setValue(name, value) {
      this[name] = value;
    },

    setCourse(value) {
      this.courseValue = value?.[0];
      if (!value) this.courseQuery = null;
    },

    setUserId(value) {
      this.userIdValue = value;

      if (!value) {
        this.b2binfo = {};
        this.contact = {};
      }
    },

    async deleteOrder() {
      if (this.paymentQuery?.id) {
        await EntityService.delete({ type: 'B2bOrderPayments', id: this.paymentQuery.id });
      }

      if (this.promoParamsQuery?.id) {
        await EntityService.delete({ type: 'PromocodeParameters', id: this.promoParamsQuery.id });
      }

      if (this.orderTypeQuery?.id) {
        await EntityService.delete({ type: 'B2bOrderType', id: this.orderTypeQuery.id });
      }

      await EntityService.delete({ type: 'B2bOrders', id: this.order.id }).then(() => {
        bus.$emit('refetchTable');
        this.$notification.success({
          message: this.$t('SOPromocodes.deleteOrderSuccess'),
        });
      });
    },

    objectNeedsSave(obj) {
      return Object.values(obj).filter((v) => ![null, undefined, ''].includes(v)).length > 1;
    },

    async saveData() {
      return Promise.all([
        this.saveEntity('B2bInfo', 'b2binfoQuery', this.b2binfo),
        this.saveEntity('B2BContact', 'contactQuery', this.contact),
        this.saveEntity('B2bOrderType', 'orderTypeQuery', this.orderType),
        this.saveEntity('B2bOrderPayments', 'paymentQuery', this.payment),
        this.saveEntity('PromocodeParameters', 'promoParamsQuery', this.promoParams),
        this.saveEntity('B2bOrders', 'order', this.order, false, false),
      ]).then(() => {
        this.$notification.success({
          message: this.$t('entity.savedSuccess'),
        });
      });
    },

    getEntityFieldsMeta(entityType) {
      const componentMeta = store.state.meta.components[entityType];
      const formConfig = FormConfigService.getFormConfig(entityType);

      const fields = formConfig.tabs.map((tab) => tab.columns.flat()).flat();
      return fields.map((fieldMeta) => ({
        ...fieldMeta,
        ...componentMeta.fields.find(({ name }) => name === fieldMeta.name),
      }));
    },

    async saveEntity(type, prop, data, updateProp = true, skipIfEmpty = true) {
      if (skipIfEmpty && !this.objectNeedsSave(data)) return true;

      const id = this[prop]?.id;
      const method = id ? 'update' : 'create';

      const { response } = await EntityService[method](this.getEntityFieldsMeta(type), {
        id,
        type,
        data,
      });

      if (updateProp) {
        this[prop] = response;
      }
    },

    async getPromocodes() {
      return new Promise((resolve, reject) => {
        this.$apollo
          .query({
            fetchPolicy: 'network-only',
            loadingKey: 'loadingKeys',
            query: customQuery('getPromocodes', 'B2bOrders', {
              orderId: this.order.id,
            }),
          })
          .then(
            ({
              data: {
                B2bOrdersQuery: { getPromocodes },
              },
            }) => {
              resolve(getPromocodes);
            },
          )
          .catch((error) => {
            this.$notification.error({
              message: this.$t('customForm.saveError'),
              description: error.message,
            });
            reject(error);
          });
      });
    },

    async refetchPromocodes() {
      setTimeout(async () => {
        const promocodes = await this.getPromocodes();
        if (this.promocodes.length === promocodes.length) {
          this.refetchPromocodes();
        } else {
          this.promocodes = promocodes;
          this.order.status = 'completed';
          this.loadingKeys--;
        }
      }, 300);
    },

    async generateCodes() {
      if (this.loadingKeys > 0) return false;
      const { mask, prefix } = this.promoParams;
      this.loadingKeys++;
      this.saveData().then(async () => {
        let fieldsMeta = await MetaService.getEntityActions('B2bOrders', [
          { action: 'generatePromocodes' },
        ]);
        fieldsMeta = fieldsMeta.generatePromocodes.fieldsList;

        this.$apollo
          .mutate({
            mutation: customMutation('generatePromocodes', 'B2bOrders', fieldsMeta),
            variables: preparePayload(
              {
                orderId: this.order.id,
                codePrefix: mask === 'Prefix' ? prefix : undefined,
                partner: this.orderType.sort === 'partner',
              },
              fieldsMeta,
            ),
          })
          .then(async () => {
            this.refetchPromocodes();
          })
          .catch((error) => {
            this.loadingKeys--;
            this.$notification.error({
              message: this.$t('customForm.saveError'),
              description: error.message,
            });
          });
      });
    },
  },
};
</script>
