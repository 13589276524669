<template>
  <a-spin :spinning="loading > 0">
    <MonacoEditor
      v-if="!resetEditor"
      v-show="showEditor"
      ref="editor"
      v-model="input"
      class="editor"
      :style="editorStyle"
      :diffEditor="!!diffValue"
      :original="diffInput"
      :options="{
        BuiltinTheme: 'vs-dark',
        automaticLayout: true,
        readOnly: disabled || readOnly,
        language: language,
        scrollbar: {
          alwaysConsumeMouseWheel: false,
        },
        suggest: {
          showKeywords: false,
          showModules: false,
        },
      }"
      @editorWillMount="registerLang"
      @editorDidMount="onEditorMounted"
    />
  </a-spin>
</template>

<script>
import MonacoEditor from 'vue-monaco';
import { bus, firstUpperCase } from '@/helpers';
import SUGGESTION_QUERY from '@/queries/suggests';

export default {
  name: 'InputCodeEditor',
  components: {
    MonacoEditor,
  },
  props: {
    config: {
      type: Object,
      required: true,
    },
    value: {
      type: [Object, String],
      default: '',
    },
    language: {
      type: String,
      default: 'json',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    suggestionOperation: {
      type: String,
      default: null,
    },
    suggestionFields: {
      type: Array,
      default: null,
    },
    focusOnMount: {
      type: Boolean,
      default: false,
    },
    diffValue: {
      type: [Object, String],
      default: null,
    },
  },
  data() {
    return {
      loading: 0,
      monaco: null,
      completionProviderDispose: null,
      resetEditor: false,
      showEditor: true,
      initialized: [false, false],
    };
  },
  computed: {
    input: {
      get() {
        return this.language === 'json' ? this.prepareData(this.value) : this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
    diffInput() {
      return this.diffValue && this.prepareData(this.diffValue, 1);
    },
    editorStyle() {
      return {
        height: `${this.config.textHeight || 400}px`,
      };
    },
    suggestionFieldsForRequest() {
      return this.suggestionFields.map(({ type, data }) => {
        if (
          [
            'EmbeddedRefsEntityConfigField',
            'EntityRefsEntityConfigField',
            'HandbookRefEntityConfigField',
            'EntityRefEntityConfigField',
          ].includes(type)
        ) {
          data = {
            ...data,
            components: data.components.map((item) => item.value),
          };
        }

        return { type, data };
      });
    },
  },
  watch: {
    suggestions(val) {
      if (val) this.registerCompletionProvider('javascript');
    },
    suggestionOperation(val) {
      if (!val) this.completionProviderDispose?.();
    },
  },
  apollo: {
    suggestions: {
      ...SUGGESTION_QUERY,
      loadingKey: 'loading',
      fetchPolicy: 'network-only',
      variables() {
        return {
          operation: this.suggestionOperation,
          fields: this.suggestionFieldsForRequest,
        };
      },
      skip() {
        return !this.suggestionOperation;
      },
      error() {
        this.emitError(
          this.$t('base.codeEditorAutocompleteError'),
          `Operation: ${JSON.stringify(this.suggestionOperation)}`,
        );
      },
    },
  },

  created() {
    bus.$on(['entityCreated', 'entityUpdated', 'resetForm'], this.reinit);
    bus.$on('monaco.updatelayout', this.updateLayout);
  },

  beforeDestroy() {
    bus.$off(['entityCreated', 'entityUpdated', 'resetForm'], this.reinit);
    bus.$off('monaco.updatelayout', this.updateLayout);
    this.completionProviderDispose?.();
  },

  updated() {
    this.unlockModifiedEditor();
  },

  methods: {
    prepareData(value, index = 0) {
      if (!this.initialized[index]) {
        this.initialized[index] = true;
        if (value) {
          try {
            if (typeof value === 'string') {
              value = JSON.parse(value);
            }

            value = JSON.stringify(value, null, 4);
          } catch (e) {
            // ...
          }
        }
      }

      return value || '';
    },

    onEditorMounted() {
      if (this.focusOnMount) {
        this.$refs.editor.$el.querySelector('textarea.inputarea').focus();
      }

      this.unlockModifiedEditor();
    },

    unlockModifiedEditor() {
      this.$nextTick(() => {
        const modifiedEditor = this.$refs.editor.getModifiedEditor();
        modifiedEditor.updateOptions({ readOnly: false });
      });
    },

    getCompletionItemKind({ type }, isMember) {
      switch (type?.toLowerCase()) {
        case 'function':
          return isMember
            ? this.monaco.languages.CompletionItemKind.Method
            : this.monaco.languages.CompletionItemKind.Function;

        case 'boolean':
        case 'number':
        case 'string':
          return isMember
            ? this.monaco.languages.CompletionItemKind.Property
            : this.monaco.languages.CompletionItemKind.Variable;

        default:
          return this.monaco.languages.CompletionItemKind.Class;
      }
    },

    getTokenPrefixAndOptions(activeWord, isMember) {
      const types = this.suggestions.types;
      let options = this.suggestions.context || [];
      let prefix = '';

      if (isMember) {
        const parents = activeWord.substring(0, activeWord.length - 1).split('.');
        while (parents.length) {
          const parentName = parents.shift();
          const parentToken = options.find((option) => option.name === parentName);
          options = types[parentToken?.type]?.properties || [];
          prefix += `${parentName}.`;
          if (!options.length) {
            return { options: [] };
          }
        }
      }

      return { prefix, options };
    },

    getCompletionItemInsertText({ name, type, arguments: funcArgs = [] }) {
      let insertText = name;
      type = type?.toLowerCase();
      if (type === 'function') {
        funcArgs = funcArgs
          .map(({ name: argName }, index) => `\${${index + 1}:${argName}}`)
          .join(', ');
        insertText = `${name}(${funcArgs})`;
      }

      return insertText;
    },

    getCompletionItemDetail(item) {
      let detail = item.type;

      if (!this.suggestions.types[item.type]) {
        detail = item.type?.toLowerCase();

        if (detail === 'array' && item.genericType) {
          let generic = item.genericType;
          if (!this.suggestions.types[generic]) generic = firstUpperCase(generic);
          detail = `${firstUpperCase(detail)}<${generic}>`;
        } else if (detail !== 'function') {
          detail = firstUpperCase(detail);
        }

        if (detail === 'function') {
          let funcArgs = item.arguments || [];
          funcArgs = funcArgs
            .map(({ name, type, genericType }) => {
              if (type) {
                type = type.toLowerCase();
                if (genericType) {
                  if (!this.suggestions.types[genericType])
                    genericType = firstUpperCase(genericType);
                  type = `${type}<${genericType}>`;
                }
                name = `${name}: ${type}`;
              }

              return name;
            })
            .join(', ');

          detail = `${detail} ${item.name}(${funcArgs})`;
        }
      }

      return detail;
    },

    // Generate custom autocomplete results
    provideCompletionItems(model, position) {
      const suggestions = [];
      const currentTextChars = model.getValueInRange({
        startLineNumber: position.lineNumber,
        startColumn: 0,
        endLineNumber: position.lineNumber,
        endColumn: position.column,
      });

      const words = currentTextChars.replace(/\s+/g, ' ').split(/[ (){}[\]]/);
      const activeWord = words[words.length - 1];
      const isMember = activeWord.charAt(activeWord.length - 1) === '.';
      const isFunctionName = words[words.length - 2] === 'function';

      if (!isFunctionName) {
        const { prefix: parentsPrefix, options: completeOptions } = this.getTokenPrefixAndOptions(
          activeWord,
          isMember,
        );

        completeOptions.forEach((option) => {
          const label = parentsPrefix + option.name;
          const completionItem = {
            label,
            sortText: `0_${option.sortText || label}`,
            kind: this.getCompletionItemKind(option, isMember),
            insertText: this.getCompletionItemInsertText(option),
            detail: this.getCompletionItemDetail(option),
            documentation: option.documentation,
          };

          if (option.type.toLowerCase() === 'function') {
            completionItem.insertTextRules =
              this.monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet;
          }

          suggestions.push(completionItem);
        });
      }

      return { suggestions };
    },

    reinit() {
      this.$nextTick(() => {
        this.initialized = [false, false];
      });
    },

    updateLayout() {
      this.showEditor = false;
      this.$nextTick(() => {
        this.showEditor = true;
      });
    },

    registerLang(monaco) {
      this.monaco = monaco;
      if (this.language === 'javascript') {
        this.registerCompletionProvider('javascript');
      }
    },

    registerCompletionProvider(lang) {
      this.completionProviderDispose?.();
      if (this.monaco) {
        this.monaco.languages.typescript.javascriptDefaults.setCompilerOptions({
          target: this.monaco.languages.typescript.ScriptTarget.ES6,
          allowNonTsExtensions: true,
          noLib: true,
        });

        // Делаем чтобы undefined в подсказках скрывался с showModules: false
        // Его можно скрыть с showVariables: false, но это скроет и наши кастомные переменные
        this.monaco.languages.typescript.javascriptDefaults.addExtraLib(
          'declare module undefined;',
          'ts:filename/facts.d.ts',
        );

        if (this.suggestionOperation && this.suggestions?.context?.length) {
          const { dispose } = this.monaco.languages.registerCompletionItemProvider(lang, {
            triggerCharacters: ['.', '('], // and anything after a space
            provideCompletionItems: this.provideCompletionItems,
          });

          this.completionProviderDispose = dispose;
        }
      }
    },
  },
};
</script>

<style lang="scss">
.editor {
  width: 100%;
  border: 1px solid #d9d9d9;

  .overflowingContentWidgets {
    position: relative;
    top: -36px;
  }
}
</style>
