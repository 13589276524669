import { ApolloClient } from 'apollo-client';
import { ApolloLink, Observable } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { InMemoryCache } from 'apollo-cache-inmemory';
import fetch from 'unfetch/polyfill'; // for IE
import TABLE_QUERY from '@/queries/table';
import { bus, storage, parseJson } from '@/helpers';
import store from '@/store';

const apiUri = storage.get('VUE_APP_API_URL') || process.env.VUE_APP_API_URL;
const authHeaderName = process.env.VUE_APP_AUTH_HEADER || 'Authorization';

const request = async (operation) => {
  const token = storage.get('authToken');
  operation.setContext({
    headers: {
      [authHeaderName]: `Bearer ${token}`,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable((observer) => {
      let handle;
      Promise.resolve(operation)
        .then((oper) => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

export function createApolloClient() {
  const httpLink = new HttpLink({
    uri: apiUri,
    credentials: 'include',
  });

  const fetchRefreshToken = (username) =>
    // eslint-disable-next-line no-use-before-define
    apolloClient.query({
      ...TABLE_QUERY,
      variables: {
        type: 'User',
        page: 0,
        pageSize: 1,
        filters: [
          {
            field: 'username',
            operator: 'EQUALS',
            value: username,
          },
        ],
      },
    });

  const updateToken = (payload) =>
    new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
          if (xhr.status === 200) {
            const tokens = parseJson(xhr.response);
            store.state.user.setTokens(tokens);
            storage.set('authToken', tokens.token);
            resolve(tokens.token);
          } else {
            reject(xhr.message || xhr.error);
          }
        }
      };

      xhr.open('POST', process.env.VUE_APP_AUTHREFRESH_URL, true);
      xhr.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
      xhr.send(JSON.stringify(payload));
    });

  const forceRelogin = () => {
    const query = {};
    if (window.location.pathname !== '/') {
      query.backUrl = window.location.pathname;
    }

    bus.$emit('logout', query);
  };

  const apolloClient = new ApolloClient({
    fetchOptions: { fetch },
    cache: new InMemoryCache(),
    link: ApolloLink.from([
      onError(({ networkError, operation, forward, response }) => {
        let statusCode = networkError?.statusCode;

        if (Array.isArray(response?.errors) && response.errors[0]?.extensions?.errorCode === 401) {
          statusCode = 401;
        } else if (
          Array.isArray(response?.errors) &&
          response.errors[0]?.extensions?.errorCode === 403
        ) {
          statusCode = 403;
        }

        const user = store.state.user;
        if (statusCode === 401 && user.authorized) {
          return new Observable((observer) => {
            fetchRefreshToken(user.username)
              .then(({ data }) =>
                updateToken({
                  token: user.authToken,
                  refreshToken: data.table.documents[0].data.refreshToken,
                }),
              )
              .then((authToken) => {
                operation.setContext(({ headers = {} }) => ({
                  headers: {
                    ...headers,
                    authorization: `Bearer ${authToken}` || null,
                  },
                }));
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };

                // Retry failed request
                forward(operation).subscribe(subscriber);
              })
              .catch((error) => {
                observer.error(error);
                forceRelogin();
              });
          });
        }

        if (statusCode === 403) {
          forceRelogin();
        } else {
          forward(operation);
        }
      }),
      requestLink,
      httpLink,
    ]),
  });

  return apolloClient;
}

export const apolloClient = createApolloClient();
