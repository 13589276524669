<template>
  <a-layout-sider v-model="collapsed" theme="light" :width="verticalWidth" class="nav-sider">
    <a-menu
      v-show="!collapsed"
      class="nav"
      mode="inline"
      :selectedKeys="selectedKeys"
      :inlineIndent="inlineIndent"
      @click="reemit('click', arguments)"
    >
      <a-menu-item-group v-for="section of sidebar" :key="section.id">
        <template slot="title">
          <span class="nav__top-section">
            {{ sectionTitle(section) }}
          </span>
        </template>

        <sidebar-section v-for="child of section.children" :key="child.id" :menuItem="child" />
      </a-menu-item-group>
    </a-menu>
  </a-layout-sider>
</template>

<script>
import store from '@/store';
import { storage } from '@/helpers';
import SidebarSection from './SidebarSection.vue';

export default {
  name: 'SidebarVerticalRoot',
  components: {
    SidebarSection,
  },

  props: {
    sidebar: {
      type: Array,
      default: () => [],
    },
    selectedKeys: {
      type: Array,
      required: true,
    },
    inlineIndent: {
      type: Number,
      required: true,
    },
    verticalWidth: {
      type: [Number, String],
      required: true,
    },
  },

  data() {
    return {
      collapsed: false,
    };
  },

  computed: {
    desktop() {
      return store.state.isDesktop;
    },
    collapseIcon() {
      return this.collapsed ? 'menu-unfold' : 'menu-fold';
    },
  },

  created() {
    this.collapsed = this.desktop && (storage.get('sidebarStatus') || false);
  },

  methods: {
    toggleMenu() {
      this.collapsed = !this.collapsed;
      storage.set('sidebarStatus', this.collapsed);
    },
    sectionTitle(section) {
      return typeof section.title === 'string'
        ? section.title
        : section.title[store.state.lang] || section.code;
    },
  },
};
</script>

<style lang="scss">
.menu--vertical {
  .nav-sider {
    padding-top: 35px;
  }

  .nav-mode-switch {
    position: absolute;
    top: 77px;
    left: 50%;
    font-size: 18px;
    transform: translateX(-50%);
    &:link {
      color: #aaa;
    }
  }

  .nav {
    &.ant-menu {
      border-right: none;

      .ant-menu-item,
      .ant-menu-submenu-title {
        width: 100%;
      }
    }

    &__top-section {
      text-transform: uppercase;
    }

    &-trigger {
      margin-left: 27px;
      margin-top: 20px;
      font-size: 15px;
    }
  }

  .ant-menu,
  .ant-layout-sider {
    transition: none !important;
  }
  .ant-menu-inline .ant-menu-selected::after,
  .ant-menu-inline .ant-menu-item-selected::after {
    display: none;
  }

  .ant-layout-sider-collapsed {
    margin-right: -90px;
  }
}

@media (min-width: $desktopBreakpoint) {
  .menu--vertical {
    .nav {
      &.ant-menu {
        padding: 8px 0 20px;
      }

      .ant-menu-item-group-title {
        padding-left: 24px;
      }
    }
  }
}
</style>
